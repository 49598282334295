import React, { useState } from 'react';
import './App.css';
import Modal from "react-modal";
import { includes } from "lodash";
import DisclaimerModal from "./modals/DisclaimerModal.js";
import Game from "./components/Game.js";

Modal.setAppElement('#root');

function App() {
	const [ disclaimerAccepted, setDisclaimerAccepted ] = useState(includes(document.cookie.split('; '), 'disclaimer-accepted=true'));

	return (
		<div className="App" id="appContainer">
			<DisclaimerModal isOpen={!disclaimerAccepted} setDisclaimerAccepted={setDisclaimerAccepted}/>
			<header className="App-header">
				Night Game 😉
			</header>
			<main className="App-main">
				{disclaimerAccepted && <Game />}
			</main>
		</div>
	);
}

export default App;
