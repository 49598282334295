import React from 'react';
import Modal from 'react-modal';


const DisclaimerModal = ({ isOpen, setDisclaimerAccepted }) => {
	return (
		<Modal
			isOpen={isOpen}
			className="App-modal"
			overlayClassName="App-modal-overlay"
		>
			<div>Night game is in beta, please expect a reduced feature set and bugs.</div>
			<div>Please follow standard BDSM safety practices while using the site. We assume no responsibility for any
				injuries occurred.
			</div>
			<div>NO personal data is stored. The app runs entirely in your browser with no requests made back to our
				servers.
			</div>
			<div><strong>Enjoy!</strong></div>
			<button onClick={() => {
				setDisclaimerAccepted(true);
				document.cookie = "disclaimer-accepted=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; SameSite=Lax";
			}}>Accept
			</button>
		</Modal>
	);
};

export default DisclaimerModal;