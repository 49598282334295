import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import {
	BODY_PART_SELECTION_FILTERS,
	CombinationsContext,
	getPleasureFiltersFromBodyParts,
} from "../context/CombinationsContext.js";
import { difference, filter, find, includes, intersection, map, upperFirst } from "lodash";
import { getData } from "../helpers/DataHelpers.js";

const {
	components,
} = getData();

const SelectBodyParts = ({ title, who, type }) => {
	const { setBodyParts, bodyParts } = useContext(CombinationsContext);

	const bodyPartChoices = type === 'all'
			? BODY_PART_SELECTION_FILTERS.all
			: BODY_PART_SELECTION_FILTERS[type]
				? intersection(bodyParts[who]['all'], BODY_PART_SELECTION_FILTERS[type])
				: bodyParts[who]['all'];

	const currentParts = intersection(bodyPartChoices, bodyParts[who][type]);

	return <div>
		<h2>{title}</h2>
		<div>
			{map(
				bodyPartChoices,
				(part) => <label key={part}>
				<span className="fakeCheckbox">{includes(currentParts, part) ? "X" : '\xa0'}</span>
				<input
					type={"checkbox"}
					checked={includes(currentParts, part)}
					onChange={({ target }) => {
						setBodyParts({
							who,
							type,
							bodyParts: target.checked
								? [ ...currentParts, part ]
								: difference(currentParts, [ part ]),
						});
					}}
				/>
				{upperFirst(part)}
			</label>)}
		</div>
	</div>;
};

const SelectUserFilters = ({ title, who, type }) => {
	const { userFilters, setUserFilters, bodyParts } = useContext(CombinationsContext);

	const filteredComponents = filter(components[type], ({ type, key }) => {
		if (key.includes("self")) {
			return false;
		}

		if (type !== 'pleasure') {
			return true;
		}

		return !includes(getPleasureFiltersFromBodyParts(who, bodyParts), key);
 	});

	const singularType = filteredComponents[0].type === 'pleasure'
		? who === 'sub'
			? 'subPleasure'
			: 'domPleasure'
		: filteredComponents[0].type;

	const currentTypeFilters = userFilters[singularType];

	return <div>
		<h2>{title}</h2>
		{map(filteredComponents, ({ key, displayName }) => <label key={key}>
			<span className="fakeCheckbox">{!includes(currentTypeFilters, key) ? "X" : '\xa0'}</span>
			<input
				type={"checkbox"}
				checked={!includes(currentTypeFilters, key)}
				onChange={({ target }) => {
					const selfKey = find(components[type], {key: `${key.replace('-sub', '')}-self`})
						? [ `${key.replace('-sub', '')}-self` ]
						: [];

					setUserFilters({
						type: singularType,
						filters: target.checked
							? difference(currentTypeFilters, [ key, ...selfKey ])
							: [ ...currentTypeFilters, key, ...selfKey ],
					});
				}}
			/>
			{displayName}
		</label>)
		}
	</div>
}

const SubBodyParts = () => <SelectBodyParts
	title="The Sub Has The Following:"
	who="sub"
	type="all"
/>;

const SubPleasureFilters = () => <SelectUserFilters
	title="The Sub Can Have The Following Pleasures:"
	who="sub"
	type="pleasures"
/>;

const SubTormentBodyParts = () => <SelectBodyParts
	title="The Sub Needs The Following Tormented:"
	who="sub"
	type="torment"
/>;

const SubTormentFilters = () => <SelectUserFilters
	title="The Sub Needs The Following Torments:"
	who="sub"
	type="torments"
/>;

const DomBodyParts = () => <SelectBodyParts
	title="The Dom Has The Following:"
	who="dom"
	type="all"
/>;

const DomPleasureFilters = () => <SelectUserFilters
	title="The Dom Needs The Following Pleasures:"
	who="dom"
	type="pleasures"
/>;

const STEPS = [
	SubBodyParts,
	SubPleasureFilters,
	SubTormentBodyParts,
	SubTormentFilters,
	DomBodyParts,
	DomPleasureFilters,
];

const InititialSetupModal = ({ setInitialSetupDone }) => {
	const [ step, setStep ] = useState(0);

	const CurrentStepComponent = STEPS[ step ];

	return (
		<Modal
			isOpen={true}
			className="App-modal"
			overlayClassName="App-modal-overlay"
		>
			<CurrentStepComponent />
			<button onClick={() => {
				if (step + 1 >= STEPS.length) {
					setInitialSetupDone(true);
					return;
				}

				setStep((currentStep) => currentStep + 1);
			}}>
				{step < STEPS.length ? 'Next' : 'Done'}
			</button>
		</Modal>
	);
};

export default InititialSetupModal;