import dataJson from '../data/data.json';
import {
	difference,
	find,
	head,
	includes,
	keys,
	map,
	reduce,
	transform,
	uniq,
} from "lodash";

const { components } = dataJson;

const PLURAL_MAP = {
	'accessory': 'accessories',
	'staticDynamic': 'static-dynamic',
	'who': 'who',
	'domPleasure': 'pleasures',
	'subPleasure': 'pleasures',
	'secondTorment': 'torments',
	'secondBodyPart': 'bodyParts',
};

export const getPluralOfType = (type) => {
	return PLURAL_MAP[ type ] || `${type}s`;
};

export const COMPONENT_TYPES = {
	position: 'position',
	place: 'place',
	torment: 'torment',
	bodyPart: 'bodyPart',
	secondTorment: 'secondTorment',
	secondBodyPart: 'secondBodyPart',
	domPleasure: 'domPleasure',
	subPleasure: 'subPleasure',
	accessory: 'accessory',
};

export const STEPS = {
	initial: 0,
	addTorment: 1,
	singlePleasure: 2,
	bothPleasure: 3,
};

/**
 * @typedef AppContentData
 * @property {object[]} combinations
 * @property {object} components
 * @property {object} subComponents
 */


/**
 * @returns {AppContentData}
 */
export const getData = () => dataJson;

export const getComponentFromKey = (type, key) => {
	const typePlural = getPluralOfType(type);

	return find(components[ typePlural ], { key });
};

export const getComponentsFromKeys = (components) => {
	return transform(components, (result, value, key) => {
		return result[ key ] = getComponentFromKey(key, value);
	});
};

export const getListName = (step, twoTorments, domHasCock) => {
	if (step === STEPS.initial) {
		return 'position-place';
	}

	if (step === STEPS.addTorment) {
		return twoTorments ? 'position-place-two-torments' : 'position-place-torment';
	}

	if (step === STEPS.singlePleasure) {
		return domHasCock ? 'position-place-torment-sub-pleasure' : 'position-place-torment-dom-pleasure';
	}

	if (step === STEPS.bothPleasure) {
		return 'position-place-torment-both-pleasure';
	}

	throw new Error('Can\'t determine list name');
};

export const getUniqueComponents = (combinations) => {
	// Don't need accessories for this
	const componentTypes = difference(keys(COMPONENT_TYPES), [ 'accessory' ]);
	const listComponentKeys = keys(head(combinations));

	return reduce(componentTypes, (accumulator, componentType) => {
		if (!includes(listComponentKeys, componentType)) {
			return {
				...accumulator,
				[ componentType ]: [],
			};
		}

		return {
			...accumulator,
			[ componentType ]: uniq(map(combinations, componentType)),
		};
	}, {});
};