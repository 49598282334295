import { CombinationsContextProvider } from "../context/CombinationsContext.js";
import { useState } from "react";
import Scenario from "./Scenario.js";
import InitialSetupModal from "../modals/InitialSetupModal.js";

const Game = () => {
	const [ initialSetupDone, setInitialSetupDone ] = useState(false);

	return <CombinationsContextProvider>
		{ initialSetupDone
			? <Scenario />
			: <InitialSetupModal setInitialSetupDone={setInitialSetupDone}/>
		}

	</CombinationsContextProvider>
};

export default Game;