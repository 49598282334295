import React, { useCallback, useContext, useLayoutEffect, useState } from 'react';
import {
	getComponentFromKey, getComponentsFromKeys,
	getData,
	getUniqueComponents,
	STEPS,
} from "../helpers/DataHelpers.js";
import { difference, findKey, head, includes, isEmpty, isEqual, map, reject, sample, upperFirst } from "lodash";
import AccessoriesModal from "../modals/AccessoriesModal.js";
import CantFindComponentModal from "../modals/CantFindComponentsModal.js";
import { CombinationsContext } from "../context/CombinationsContext.js";

const getStepLabel = ({ step, domHasCock }) => {
	const steps = {
		[ STEPS.initial ]: 'Position',
		[ STEPS.addTorment ]: 'Add Torment',
		[ STEPS.singlePleasure ]: domHasCock ? 'Sub Pleasure' : 'Dom Pleasure',
		[ STEPS.bothPleasure ]: 'Both Pleasure',
	};

	return steps[step];
};

const getParsedName = ({ type, name, first = true }) => {
	if (type === 'bodyPart') {
		return <span className={'bodyPartHighlight'}>{name}</span>;
	}

	const namePart = name.replace(/{(.*?)\/(.*?)}/, first ? "$1" : "$2");

	return <span
		dangerouslySetInnerHTML={{ __html: namePart.replace('[', `<span class='${type}Highlight'>`).replace(']', '</span>') }} />;
};

const Scenario = () => {
	const {
		getValidCombinations,
		domHasCock,
	} = useContext(CombinationsContext);

	const getComponents = useCallback(({
		step,
		previousComponents,
		...currentState
	}) => {
		const possibleCombinations = getValidCombinations({
			step,
			previousComponents,
			...currentState,
		});

		if (possibleCombinations.length === 0) {
			return {};
		}

		if (possibleCombinations.length === 1) {
			return getComponentsFromKeys(head(possibleCombinations));
		}

		const uniqueComponents = getUniqueComponents(possibleCombinations);

		const componentTypeToPick = findKey(uniqueComponents, (components) => components.length > 1);

		if (componentTypeToPick) {
			return getComponents({
				step,
				previousComponents,
				...currentState,
				[ componentTypeToPick ]: getComponentFromKey(componentTypeToPick, sample(uniqueComponents[ componentTypeToPick ])),
			});
		}

		return getComponentsFromKeys(sample(possibleCombinations));
	}, [ getValidCombinations ]);

	const [ components, setComponents ] = useState({
		position: null,
		place: null,
		torment: null,
		bodyPart: null,
		secondTorment: false,
		secondBodyPart: false,
		domPleasure: null,
		subPleasure: null,
		accessories: [ 'hands-behind' ],
	});
	const [ previousComponents, setPreviousComponents ] = useState(components);
	const [ step, setStep ] = useState(0);
	const [ accessoriesIsOpen, setAccessoriesIsOpen ] = useState(true);
	const [ cantFindComponentsIsOpen, setCantFindComponentsIsOpen ] = useState(false);

	const updateStep = (newStepCallback) => {
		const newComponents = components;
		if (newStepCallback(step) > STEPS.addTorment) {
			newComponents.position = null;
			newComponents.place = null;
		}

		newComponents.torment = null;
		newComponents.bodyPart = null;
		newComponents.secondTorment = false;
		newComponents.secondBodyPart = false;
		newComponents.domPleasure = null;
		newComponents.subPleasure = null;
		newComponents.accessories = difference(components.accessories, [ 'hands-behind' ]);
		if (newStepCallback(step) < STEPS.singlePleasure) {
			newComponents.accessories.push('hands-behind');
		}

		setComponents(newComponents);
		setPreviousComponents(newComponents);
		setStep(newStepCallback);
	};

	const updateComponents = (newComponents) => {
		setComponents((components) => {return { ...components, ...newComponents };});
	};

	const setAccessories = (accessories) => {
		updateComponents({ accessories });
	};

	useLayoutEffect(
		() => {
			const componentsResult = getComponents({
				step,
				previousComponents,
				...components,
			});

			if (isEmpty(componentsResult)) {
				setCantFindComponentsIsOpen(true);
				return;
			}

			const {
				position: newPosition,
				place: newPlace,
				torment: newtorment = null,
				bodyPart: newBodyPart = null,
				secondTorment: newSecondtorment = false,
				secondBodyPart: newSecondBodyPart = false,
				domPleasure: newDomPleasure = null,
				subPleasure: newSubPleasure = null,
			} = componentsResult;

			const newComponents = {
				position: newPosition,
				place: newPlace,
				torment: newtorment,
				bodyPart: newBodyPart,
				secondTorment: newSecondtorment,
				secondBodyPart: newSecondBodyPart,
				domPleasure: newDomPleasure,
				subPleasure: newSubPleasure,
				accessories: components.accessories,
			};

			if (isEqual(components, newComponents)) {
				return;
			}

			setComponents(newComponents);
			setPreviousComponents(newComponents);
		},
		[ components, getComponents, previousComponents, step ],
	);

	const {
		position,
		place,
		torment,
		bodyPart,
		secondTorment,
		secondBodyPart,
		domPleasure,
		subPleasure,
		accessories,
	} = components;

	const possibleAccessories = reject(map(getData().components.accessories), { key: 'hands-behind' });

	return (
		<>
			<AccessoriesModal
				isOpen={accessoriesIsOpen}
				setIsOpen={setAccessoriesIsOpen}
				possibleAccessories={possibleAccessories}
				currentAccessories={accessories}
				setAccessories={setAccessories}
			/>
			<CantFindComponentModal
				isOpen={cantFindComponentsIsOpen}
				setIsOpen={setCantFindComponentsIsOpen}
				setAccessoriesIsOpen={setAccessoriesIsOpen}
				currentComponents={components}
				updateComponents={updateComponents}
				step={step}
			/>
			<div className={"App-steps"} key={'actions'}>
				<div key={'steps'}>
					{step > STEPS.initial &&
						<button onClick={() => updateStep((step) => step - 1)}>Prev Step</button>
					}
					{step < STEPS.bothPleasure &&
						<button onClick={() => updateStep((step) => step + 1)}>Next: {getStepLabel({
							step: step + 1,
							domHasCock,
						})}</button>
					}
				</div>
				<div key={'reset'}>
					{step > STEPS.initial &&
						<>
							<button onClick={() => updateComponents({
								position: null,
								place: null,
								torment: null,
								bodyPart: null,
								secondTorment: secondTorment === false ? false : null,
								secondBodyPart: secondTorment === false ? false : null,
								subPleasure: null,
								domPleasure: null,
							})}>New Everything
							</button>
						</>
					}
					<button onClick={() => {setAccessoriesIsOpen(true);}}>Change Accessories</button>
				</div>
			</div>
			<div className={"App-components"} key={'components'}>
				{(position) &&
					<div key={'position'}>
						{position && <h2>Position
							Sub {getParsedName(position)} {place && getParsedName(place)},
							with {includes(accessories, 'hands-behind') ? 'hands restrained' : 'hands restrained unless needed'}</h2>}
						<button onClick={() => updateComponents({ position: null, place: null })}>New Position</button>
					</div>
				}
				{torment && bodyPart && <div key={'torment'}>
					<h2>{getParsedName(torment)} {getParsedName(bodyPart)}</h2>
					<button onClick={() => updateComponents({ torment: null })}>New Torment</button>
					<button onClick={() => updateComponents({ bodyPart: null })}>New Body Part</button>
					<button onClick={() => updateComponents({ torment: null, bodyPart: null })}>New Torment And Body
						Part
					</button>
				</div>}
				{
					step === STEPS.addTorment &&
					((secondTorment && secondBodyPart)
							? <div key={'secondTorment'}>
								<h2>{getParsedName(secondTorment)} {getParsedName(secondBodyPart)}</h2>
								<button onClick={() => updateComponents({ secondTorment: null })}>New Second Torment
								</button>
								<button onClick={() => updateComponents({ secondBodyPart: null })}>New Second Body Part
								</button>
								<button
									onClick={() => updateComponents({ secondTorment: null, secondBodyPart: null })}>New
									Second Torment And Body Part
								</button>
								<div>
									<button onClick={() => updateComponents({
										torment: null,
										bodyPart: null,
										secondTorment: null,
										secondBodyPart: null,
									})}>New Both Torments
									</button>
									<button onClick={() => updateComponents({
										secondTorment: false,
										secondBodyPart: false,
									})}>Remove Second Torment
									</button>
								</div>
							</div>
							: (secondTorment === false)
							&& <div key={'secondTorment'}>
								<button
									onClick={() => updateComponents({
										secondTorment: null,
										secondBodyPart: null,
									})}>Add Second Torment
								</button>
							</div>
					)
				}
				{
					subPleasure && !domPleasure && <div key={'pleasure'}>
						<h2>Sub {getParsedName({
							type: 'subPleasure',
							name: subPleasure.name,
							first: true,
						})}</h2>
						<button onClick={() => updateComponents({ subPleasure: null })}>New Sub Pleasure</button>
					</div>
				}
				{
					domPleasure && (step !== STEPS.bothPleasure || subPleasure) && <div key={'pleasure'}>
						<h2>{getParsedName({
							type: 'domPleasure',
							name: domPleasure.name,
						})} {subPleasure && subPleasure !== domPleasure && <>
							while Sub {getParsedName({ type: 'subPleasure', name: subPleasure.name, first: false })}</>}</h2>
						<button onClick={() => updateComponents({ domPleasure: null })}>New Dom Pleasure</button>
						{subPleasure && <>
							{domPleasure.key !== subPleasure.key &&
								<button onClick={() => updateComponents({ subPleasure: null })}>New Sub Pleasure</button>}
							<button onClick={() => updateComponents({ domPleasure: null, subPleasure: null })}>New Sub And
								Dom
								Pleasure
							</button>
						</>
						}
					</div>
				}
			</div>
		</>
	)
		;
};

export default Scenario;