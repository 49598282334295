import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { STEPS } from "../helpers/DataHelpers.js";


const CantFindComponentModal = ({ isOpen, setIsOpen, step, setAccessoriesIsOpen, currentComponents, updateComponents: parentUpdateComponents }) => {
	const updateComponents = (props) => {
		setIsOpen(false);
		parentUpdateComponents(props);
	}

	useEffect(() => {
		if (isOpen) {
			setAccessoriesIsOpen(false)
		}
	}, [isOpen, setAccessoriesIsOpen]);

	return (
		<Modal
			key="cant-find-components-modal"
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			className='App-modal'
			overlayClassName='App-modal-overlay'
		>
			<div className={'App-cant-find-options'} key='description'>
				<h2>Can't find a new scenario. Try changing more than one thing or remove accessories</h2>
				<div>
					<button onClick={() => updateComponents({ position: null, place: null } )}>New Position</button>
				</div>
				<div>
					<button onClick={() => updateComponents({
						torment: null,
						bodyPart: null,
						secondTorment: currentComponents.secondTorment === false ? false : null,
						secondBodyPart: currentComponents.secondTorment === false ? false : null,
					})}>New Torments
					</button>
				</div>
				{step >= STEPS.singlePleasure && <div>
					<button onClick={() => updateComponents({
						domPleasure: null,
						subPleasure: null,
					})}>New Pleasures
					</button>
				</div>
				}
				<div>
					<button onClick={() => updateComponents({
						position: null,
						place: null,
						torment: null,
						bodyPart: null,
						secondTorment: currentComponents.secondTorment === false ? false : null,
						secondBodyPart: currentComponents.secondTorment === false ? false : null,
						subPleasure: null,
						domPleasure: null,
					})}>New Everything
					</button>
				</div>
				<div>
					<button onClick={() => {setAccessoriesIsOpen(true); setIsOpen(false);}}>Accessories</button>
				</div>
			</div>
		</Modal>
	);
};

export default CantFindComponentModal;