import React from 'react';
import Modal from 'react-modal';
import { difference, includes, map } from "lodash";


const AccessoriesModal = ({ isOpen, setIsOpen, possibleAccessories, currentAccessories, setAccessories }) => {
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={() => setIsOpen(false)}
			className='App-modal'
			overlayClassName='App-modal-overlay'
		>
			<h2>Choose Accessories</h2>
			<div key={'options'}>
				{map(possibleAccessories, ({ key, name }) =>
					<label key={key}>
						<span className="fakeCheckbox">{includes(currentAccessories, key) ? "X" : '\xa0'}</span>
						<input
							type="checkbox"
							name={key}
							checked={includes(currentAccessories, key)}
							onChange={({ target }) => {
								if (target.checked) {
									setAccessories([ ...currentAccessories, key ]);
								} else {
									setAccessories(difference(currentAccessories, [ key ]));
								}
							}}
						/>
						{name}
					</label>,
				)}
			</div>
			<div key={'closeButton'}>
				<button onClick={() => setIsOpen(false)}>Close</button>
			</div>
		</Modal>
	);
};

export default AccessoriesModal;