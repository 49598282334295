import { createContext, useCallback, useMemo, useReducer } from "react";
import { difference, filter, find, head, includes, intersection, keys, map, mapValues, transform, uniq } from "lodash";
import { COMPONENT_TYPES, getData, getListName } from "../helpers/DataHelpers.js";

export const CombinationsContext = createContext({});

export const BODY_PART_SELECTION_FILTERS = {
	all: [
		'pussy',
		'tits',
		'cock',
		'balls',
	],
	pleasure: [
		'pussy',
		'cock',
		'ass',
	],
}

const {
	components,
	subComponents,
	combinations: rawCombinations,
} = getData();

export const getPleasureFiltersFromBodyParts = (who, bodyParts) => {
	return filter(map(components.pleasures, 'key'), (key) => {
		// Check if this is actually a pleasure for the pleasured
		if (!find(subComponents[ key ], (subComponent) => subComponent[ 0 ] === who)) {
			return true;
		}

		// Check if the pleasured has the right body part
		const whoBodyPart = find(subComponents[ key ],
			(subComponent) =>
				subComponent.length === 2
				&& subComponent[ 1 ] === who,
		);

		if (whoBodyPart && !includes(bodyParts[ who ].pleasure, whoBodyPart[ 0 ])) {
			return true;
		}

		const other = (who === 'sub' ? 'dom' : 'sub');

		const otherBodyPart = find(subComponents[ key ],
			(subComponent) =>
				subComponent.length === 2
				&& subComponent[ 1 ] === other,
		);

		// Check if the pleasurer has the right body part
		if (otherBodyPart && !includes(bodyParts[ other ].all, otherBodyPart[ 0 ])) {
			return true;
		}

		// Good to go!
		return false;
	});
};

const getSanitizedBodyParts = (type, bodyParts) => {
	if (type !== 'all') {
		return bodyParts;
	}

	if (!includes(bodyParts, 'tits') && !includes(bodyParts, 'chest')) {
		bodyParts.push('chest');
	}

	return uniq([
		...bodyParts,
		'ass',
		'nipples',
		'thighs',
	])
}

export const CombinationsContextProvider = ({ children }) => {
		const [ userFilters, setUserFilters ] = useReducer(
			(currentUserFilters, { type, filters }) => {
				return {
					...currentUserFilters,
					[ type ]: filters,
				}
			},
			transform(COMPONENT_TYPES, (defaultFilters, componentType) => {
				defaultFilters[ componentType ] = [];
			}),
		);

		const [ bodyParts, setBodyParts ] = useReducer(
			(currentBodyParts, { who, type, bodyParts }) => {
				const sanitizedBodyParts = getSanitizedBodyParts(type, bodyParts);

				if (type === 'all') {
					currentBodyParts[who].pleasure = intersection(BODY_PART_SELECTION_FILTERS.pleasure, sanitizedBodyParts);
					if (who === 'sub') {
						currentBodyParts[ who ].torment = sanitizedBodyParts;
					}
				}

				return {
					...currentBodyParts,
					[ who ]: {
						...currentBodyParts[ who ],
						[ type ]: sanitizedBodyParts,
					},
				};
			},
			{
				sub: {
					all: [],
					pleasure: [],
					torment: [],
				},
				dom: {
					all: [],
					pleasure: [],
				},
			},
		);

		const combinations = useMemo(() => {
			const filters = { ...userFilters };

			filters.subPleasure = [
				...filters.subPleasure,
				...getPleasureFiltersFromBodyParts('sub', bodyParts),
			];

			filters.domPleasure = [
				...filters.domPleasure,
				...getPleasureFiltersFromBodyParts('dom', bodyParts),
			];

			filters.bodyPart = [
				...filters.bodyPart,
				...difference(map(components.bodyParts, 'key'), bodyParts.sub.torment),
			];

			filters.secondBodyPart = filters.bodyPart;

			return transform(
				rawCombinations,
				(newCombinations, combination_list, key) =>
					newCombinations[ key ] = filter(combination_list, (components) => {
							return !find(components, (value, key) => filters[ key ] !== undefined && filters[ key ].indexOf(value) !== -1);
						},
					),
			);
		}, [ userFilters, bodyParts ]);

	const domHasCock = includes(bodyParts.dom.all, 'cock');

	const getValidCombinations = useCallback(({
			step,
			previousComponents,
			position = null,
			place = null,
			accessories = [],
			bodyPart = null,
			torment = null,
			secondTorment = false,
			secondBodyPart = false,
			domPleasure = null,
			subPleasure = null,
		}) => {
			const listName = getListName(step, secondTorment !== false, domHasCock);

			const currentComponents = {
				position,
				place,
				accessories,
				torment,
				bodyPart,
				secondTorment,
				secondBodyPart,
				domPleasure,
				subPleasure,
			};

			const list = combinations[ listName ];
			const listComponentKeys = keys(head(list));

			if (!find(listComponentKeys, (key) => currentComponents[ key ] === null)) {
				return [ mapValues(currentComponents, 'key') ];
			}

			return filter(list, (possibleComponents) => {
				return !Boolean(find(possibleComponents, (possibleValue, type) => {
					if (type === 'accessories') {
						return difference(accessories, possibleValue).length !== 0;
					}

					const currentValue = currentComponents[ type ]?.key || null;
					const previousValue = previousComponents[ type ]?.key || null;

					if (currentValue === null && previousValue !== null && possibleValue === previousValue) {
						return true;
					}

					return currentValue !== null && possibleValue !== currentValue;
				}));
			});
		}, [ combinations, domHasCock ]);

		return <CombinationsContext.Provider value={{
			combinations,
			userFilters,
			setUserFilters,
			bodyParts,
			setBodyParts,
			getValidCombinations,
			domHasCock,
		}}>
			{children}
		</CombinationsContext.Provider>;
	}
;